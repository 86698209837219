<form (ngSubmit)="onSubmit()" #paymentForm="ngForm" id="paymentForm" name="paymentForm">

  <br>
  <div *ngIf="!submitted">
    <br>
    <div class="row">
      <div class="col text-center">

        <table align="center">
          <tr>

            <td colspan="4" align="center">
              <img src="./../../assets/images/frame.svg" class="img-thumbnail">
            </td>
          </tr>
          <tr>
            <td colspan="4" align="center">
              <b><label style="font-weight: bold;">Ingrese el código de seguridad</label></b>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td align="center"> 
               <input autofocus type="text" id="cvv" class="form-control" title="CVV" alt="CVV" required 
                [(ngModel)]="cvv"
                name="cvv"  [maxlength]="maxlength"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                style="border-color: #41C5F9;margin-right: 15px;" onkeypress="soloNumeros(event)"
                onblur="javascript:verificarNumerosChange(this);" (keyup)="verificaCVV()" />

            </td>
          </tr>
          <tr *ngIf="promotions.length>0" style="display: none;">
            <td align="center" style="margin-top: 10px;padding-top: 10px;">
              <div>
              <p style="width:40%">Tu tarjeta cuenta con promociones a meses.</p>
              <p style="width:40%">Selecciona el plazo que desees para diferir tu pago, o sino quieres aplicar alguna promoción, da clic en Pagar.</p>
              <select  class="form-select form-select-sm" name="msi" [(ngModel)]="promotion">
                <option [ngValue]="null">--Seleccione una opción--</option>
                <option *ngFor="let promotion of promotions" [ngValue]="promotion">{{promotion}}</option>
              </select>
            </div> 
            </td>
          </tr>
          <tr>
            <td align="left">
              <div *ngIf="withErrorPromotions" [innerHTML]="messagePromotions"  style="color:red"></div> 
              <div *ngIf="withError" [innerHTML]="message"  style="color:red"></div> 
            </td>
          </tr>


          <tr>
            <td colspan="4" align="center">
              <button type="submit" class="btn btn-success" id="button" [disabled]="(withError || withErrorPromotions || loadingPromotions) && cvv == ''">
                Pagar
              </button>   
              <button type="button" id="loadPromotions" (click)="loadPromotionsAction()" style="display:none;">
                Pagar
              </button>  
            </td>
          </tr>

        </table>


      </div>

    </div>

  </div>
  <div *ngIf="submitted">
    <table align="center"  width="100%">
<tr>
  <td align="center"><!--img src="./../../assets/images/payment.svg" class="img-thumbnail"--></td>
</tr>
<tr>
  <td align="center"><img src="./../../assets/images/conveyor_1_v1.gif" class="img-thumbnail, responsive"></td>
</tr>
<tr>
  <td align="center"> <label style="font-weight: bold;">Estamos procesando tu pago...</label></td>
</tr>
<!--tr>
  <td align="center"><img src="./../../assets/images/frame.svg" class="img-thumbnail"></td>
</tr-->

    </table>
  
  
  </div>

  <span id="number_span" style="display:none;"></span>
  <span id="expiry_month_span" style="display:none;"></span>
  <span id="expiry_year_span" style="display:none;"></span>
  <span id="cvv_span" style="display:none;"></span>
  <span id="amount_span" style="display:none;"></span>
  <span id="name_span" style="display:none;"></span>
  <span id="email_span" style="display:none;"></span>
  <span id="phone_span" style="display:none;"></span>
  <span id="street_span" style="display:none;"></span>
  <span id="city_span" style="display:none;"></span>
  <span id="zipcode_span" style="display:none;"></span>
  <span id="town_span" style="display:none;"></span>
  <span id="token_span" style="display:none;"></span>
  <span id="idTransaction_span" style="display:none;"></span>
  <span id="months_span" style="display:none;"></span>
  <span id="isGift" style="display:none;"></span>

</form>